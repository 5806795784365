import React, { useState, useEffect, useRef } from "react";
import "./navBurger.scss";
import { useNavigate } from "react-router-dom";
import BtnCircular from "../BtnCircular/BtnCircular";
import { useLogoutMutation } from "../../hooks/auth";
import { Returntext } from "../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../hooks/content";

// Import SVGs as React components
import { ReactComponent as BurgerBtnIcon } from "../../icons/burger-btn.svg";
import { ReactComponent as HelloIcon } from "../../icons/hello.svg";
import { ReactComponent as RaSmIcon } from "../../icons/ra-sm.svg";
import { ReactComponent as HomeIcon } from "../../icons/landing/common/burger/home.svg";
import { ReactComponent as PriceIcon } from "../../icons/landing/common/burger/price.svg";
import { ReactComponent as DeliveryIcon } from "../../icons/landing/common/burger/delivery.svg";
import { ReactComponent as AboutUsIcon } from "../../icons/landing/common/burger/aboutUs.svg";
import { ReactComponent as LogoutIcon } from "../../icons/log-out-nav.svg";
import { ReactComponent as TermsAndConditionsIcon } from "../../icons/landing/common/burger/termsAndConditions.svg";

const NavBurger = () => {
  const [active, setActive] = useState(false);
  const popOverRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const onNavigateClick = () => {
    navigate("/account-settings");
    setActive(false);
  };

  const { mutate: logout } = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/sign-in";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const landings = [
    {
      name: Returntext(contentManagementData, "navbar_home", lang),
      image: <HomeIcon />,
      link: "/landing/home",
    },
    {
      name: Returntext(contentManagementData, "navbar_prices", lang),
      image: <PriceIcon />,
      link: "/landing/prices",
    },
    {
      name: Returntext(contentManagementData, "navbar_delivery", lang),
      image: <DeliveryIcon />,
      link: "/landing/delivery",
    },
    {
      name: Returntext(contentManagementData, "navbar_aboutus", lang),
      image: <AboutUsIcon />,
      link: "/landing/about",
    },
    {
      name: Returntext(contentManagementData, "TAC", lang),
      image: <TermsAndConditionsIcon />,
      link: "/landing/terms",
    },
  ];

  const handleClickOutside = (event) => {
    if (popOverRef.current && !popOverRef.current.contains(event.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active]);

  return (
    <div className="nav-burger" ref={popOverRef}>
      {userData.first_name && userData.last_name && (
        <div className="pre" onClick={() => setActive(!active)}>
          <BurgerBtnIcon className="icon" />
          <div className="name">
            {userData?.first_name?.charAt(0).toUpperCase()}
            {userData?.last_name?.charAt(0).toUpperCase()}
          </div>
        </div>
      )}

      {active && (
        <div className="post">
          <div className="hello" onClick={() => onNavigateClick()}>
            <div className="left">
              <HelloIcon className="lc" />
              <div className="txts">
                <div className="main">
                  {Returntext(contentManagementData, "hello", lang)}{" "}
                  {userData?.first_name}
                </div>
                <div className="sub">
                  {Returntext(contentManagementData, "navbar_room", lang)}{" "}
                  {userData.room_number}
                </div>
              </div>
            </div>
            <div className="right">
              <BtnCircular
                size={"sm"}
                mainColor={"grayBorder"}
                svg={<RaSmIcon />}
                disabled={false}
              />
            </div>
          </div>

          <div className="main">
            <div className="links">
              {landings.map((land) => (
                <div
                  className="li"
                  onClick={() => navigate(land.link)}
                  key={land.link}
                >
                  {land.image}
                  <div className="li-name">{land.name}</div>
                </div>
              ))}
              <div
                className="li"
                onClick={handleLogout}
                style={{ borderBottom: "unset" }}
              >
                <LogoutIcon className="hi" />
                <div className="li-name">
                  {Returntext(contentManagementData, "signOut", lang)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBurger;
