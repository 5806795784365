import React from "react";
import "./addressesBox.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import NoItems from "../../../ds/NoItems/NoItems";
import ListItem from "../../../ds/ListItem/ListItem";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import PopOver from "../../atoms/PopOver/PopOver";
import axios from "axios";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import Cookies from "js-cookie";
import { useCustomerAddresses } from "../../../hooks/address";
import { ReactComponent as NoAddressIcon } from "../../../icons/no-address.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { useSnackbar } from "notistack";

const AddressesBox = ({
  setActiveItem,
  setDeleteItem,
  setAddAddressPopUp,
  setEditAddressPopUp,
}) => {
  const access_token = Cookies.get("access_token");
  const { customerAddresses, customerAddressesRefetch } =
    useCustomerAddresses();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdate = (cid) => {
    const params = {
      accessToken: access_token,
    };

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/customer-addresses/update-default-address`,
        {
          prevId: customerAddresses.find(
            (address) => address.isDefault === true
          ).id,
          currentId: cid,
        },
        { params }
      )
      .then((response) => {
        customerAddressesRefetch();
        enqueueSnackbar(
          Returntext(
            contentManagementData,
            "ErrorMessages-addressupdate",
            lang
          ),
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          Returntext(
            contentManagementData,
            "ErrorMessages-addressupdateerror",
            lang
          ),
          {
            variant: "error",
          }
        );
      });
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="addresses-box">
      <AccountDetailsBoxTitle
        main={Returntext(
          contentManagementData,
          "DeliverySettingsBOX1Headlines",
          lang
        )}
        sub={Returntext(
          contentManagementData,
          "DeliverySettingsBOX1Subheadline",
          lang
        )}
      />

      {(customerAddresses === undefined || customerAddresses.length === 0) && (
        <NoItems
          icon={NoAddressIcon}
          main={Returntext(
            contentManagementData,
            "DeliverySettingsEmptyHeadline",
            lang
          )}
          sub={Returntext(
            contentManagementData,
            "DeliverySettingsEmptySubheadline",
            lang
          )}
        />
      )}

      <div className="addresses">
        {customerAddresses === undefined ||
          (customerAddresses.length > 0 &&
            customerAddresses.map((address, i) => (
              <div key={i}>
                <ListItem
                  hasBorder={false}
                  hasPadding={false}
                  hasIcon={true}
                  headline={address.title !== "" ? address.title : address.tag}
                  description={`${address.address}, ${address.city}`}
                  icon={address.tag === "home" ? <HomeIcon /> : <WorkIcon />}
                  buttons={
                    <>
                      {address.isDefault && (
                        <OrderTableLabel
                          txt={Returntext(
                            contentManagementData,
                            "default",
                            lang
                          )}
                        />
                      )}
                      <PopOver
                        setActiveItem={setActiveItem}
                        setDeleteItem={setDeleteItem}
                        setEditAddressPopUp={setEditAddressPopUp}
                        item={address}
                        handleUpdate={handleUpdate}
                      />
                    </>
                  }
                />
                <div className="break" />
              </div>
            )))}
      </div>

      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "DeliverySettingsButton", lang)}
        disabled={false}
        size={"standard"}
        icon={true}
        iconPosition={"left"}
        svg={<AddIcon />}
        mainColor={"green"}
        onClick={() => setAddAddressPopUp(true)}
      />
    </div>
  );
};

export default AddressesBox;
