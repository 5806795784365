import React from "react";
import { ReactComponent as D1Icon } from "../../../icons/d1.svg";
import { ReactComponent as D2Icon } from "../../../icons/d2.svg";
import { ReactComponent as D3Icon } from "../../../icons/d3.svg";
import { ReactComponent as D4Icon } from "../../../icons/d4.svg";
import { ReactComponent as D5Icon } from "../../../icons/d5.svg";

const RenderIcon = ({ iconType }) => {
  switch (iconType) {
    case "9:00-12:00":
      return <D1Icon />;
    case "12:00-15:00":
      return <D2Icon />;
    case "15:00-19:00":
      return <D3Icon />;
    case "19:00-22:00":
      return <D4Icon />;
    case "22:00-01:00":
      return <D5Icon />;
    default:
      return null;
  }
};

export default RenderIcon;
