import React from "react";
import "./paymentMethodsBox.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import ListItem from "../../../ds/ListItem/ListItem";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import WalletPopOver from "../../atoms/WalletPopOver/WalletPopOver";
import { usePaymentCardsQuery } from "../../../hooks/cards";
import axios from "axios";
import Cookies from "js-cookie";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { ReactComponent as McIcon } from "../../../icons/mc.svg";
import { ReactComponent as AmexIcon } from "../../../icons/amex.svg";
import { ReactComponent as VisaIcon } from "../../../icons/visa.svg";
import { ReactComponent as UnknownIcon } from "../../../icons/unknown.svg";
import { useSnackbar } from "notistack";

const PaymentMethodsBox = ({ setActiveItem, setDeleteItem, saveCard }) => {
  const { data: paymentCards, refetchPaymentCards } = usePaymentCardsQuery();
  const { enqueueSnackbar } = useSnackbar();

  const handleSetDefault = async (cardId) => {
    try {
      const accessToken = Cookies.get("access_token");

      const response = await axios.put(
        `${process.env.REACT_APP_PAYMENTS_URL}/payments/cards/${cardId}?accessToken=${accessToken}`,
        { isDefault: true },
        {
          headers: {
            "Content-Type": "application/json",
            "x-public-key": process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      refetchPaymentCards();
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-cardupdate", lang),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-cardupdate2", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const detectIcon = (card) => {
    switch (card?.cardType) {
      case "mc":
        return <McIcon />;
      case "amex":
        return <AmexIcon />;
      case "visa":
        return <VisaIcon />;
      default:
        return <UnknownIcon />;
    }
  };

  return (
    <div className="pmb">
      <div className="card-details">
        {paymentCards !== undefined && paymentCards.length > 0 && (
          <AccountDetailsBoxTitle
            main={Returntext(contentManagementData, "payment_methods", lang)}
            sub={Returntext(contentManagementData, "card_manage", lang)}
          />
        )}

        {paymentCards !== undefined &&
          paymentCards.length > 0 &&
          paymentCards.map((card, i) => (
            <div className="pcard-item" key={i}>
              <ListItem
                hasBorder={false}
                hasPadding={false}
                hasIcon={true}
                headline={`*** ${card.mask.slice(-4)}`}
                icon={detectIcon(card)}
                buttons={
                  <>
                    {card.isDefault && (
                      <OrderTableLabel
                        txt={Returntext(contentManagementData, "default", lang)}
                      />
                    )}
                    {!card.isDefault && (
                      <WalletPopOver
                        setActiveItem={setActiveItem}
                        setDeleteItem={setDeleteItem}
                        setDefault={() => handleSetDefault(card.id)}
                        item={card}
                      />
                    )}
                  </>
                }
              />
              <div className="break" />
            </div>
          ))}
      </div>

      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "btn_add_card", lang)}
        disabled={false}
        size={"standard"}
        icon={true}
        iconPosition={"left"}
        svg={<AddIcon />}
        mainColor={"green"}
        onClick={saveCard}
      />
    </div>
  );
};

export default PaymentMethodsBox;
