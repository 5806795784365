import React, { useEffect, useState } from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import CustomBodyS3 from "../../molecules/CustomBodyS3/CustomBodyS3";
import axios from "axios";
import useUpdateOrderByNusxa from "../../../hooks/custom";
import { useUpdateOrder } from "../../../hooks/orders";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const CustomContentS3 = ({
  cancelClick,
  setTipsDrawerActive,
  step,
  activeOrder,
}) => {
  const [sign, setSign] = useState("");
  const { updateOrderByNusxa } = useUpdateOrderByNusxa();
  const { refetchOrdersOnSuccess } = useUpdateOrder();
  const { enqueueSnackbar } = useSnackbar();
  const access_token = Cookies.get("access_token");

  const getLink = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/signify/template/${activeOrder.id}?accessToken=${access_token}`
      );
      setSign(response.data.url);
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleUpdateCustomStatus = async () => {
    try {
      await updateOrderByNusxa({
        id: activeOrder?.id,
        newData: { custom_status: 8 },
      });
      await refetchOrdersOnSuccess();
      cancelClick();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "sign") {
        handleUpdateCustomStatus();
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-Signature", lang),
          {
            variant: "success",
          }
        );
        cancelClick();
      } else if (event.data.type === "rejected") {
        console.log(event.data.type);
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-Signature2", lang),
          {
            variant: "error",
          }
        );
        cancelClick();
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className={`drawer-wrap ${sign !== "" && "lg"}`}>
      <div className="header">
        <div className="left"></div>

        <div className="middle">
          {Returntext(contentManagementData, "customDrawerT", lang)}
        </div>

        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      <div className={`body lg ${sign !== "" && "frame"}`}>
        {sign === "" && (
          <CustomBodyS3
            main={Returntext(
              contentManagementData,
              "customDrawerStep1_2MH",
              lang
            )}
            step={step}
            setTipsDrawerActive={setTipsDrawerActive}
            getLink={getLink}
            activeOrder={activeOrder}
          />
        )}
        {sign !== "" && (
          <iframe src={`${sign}`} frameBorder="0" className="iframe"></iframe>
        )}
      </div>
    </div>
  );
};

export default CustomContentS3;
