import React, { useEffect, useState } from "react";
import "./customPaymentBox.scss";
import UploadBox from "../../../ds/UploadBox/UploadBox";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useFileUpload, useOrders } from "../../../hooks/orders";
import { ReactComponent as Cs2Icon } from "../../../icons/cs2.svg";
import { ReactComponent as WarnRecIcon } from "../../../icons/warn-rec.svg";

const CustomPaymentBox = ({ setFiles, files, activeOrder, setActiveOrder }) => {
  const [ct, setCt] = useState(0);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { handleFileUpload } = useFileUpload();
  const user = JSON.parse(localStorage.getItem("user"));
  const { orders, refetchOrders } = useOrders(
    user.id,
    "RECEIVED_IN_TBILISI_WAREHOUSE"
  );

  useEffect(() => {
    const handleFiles = async () => {
      try {
        const dataFiles = {
          files: files,
          orderId: activeOrder.id,
          fileType: "ADDITIONALFILES",
        };
        await handleFileUpload(dataFiles);
        await refetchOrders();
        setFiles([]);
      } catch (err) {
        console.log(err);
        refetchOrders();
      } finally {
        const updatedOrder = orders.find(
          (order) => order.id === activeOrder.id
        );
        if (updatedOrder) {
          setActiveOrder(updatedOrder);
        }
      }
    };
    if (files.length > 0) {
      handleFiles();
    }
  }, [files]);

  return (
    <div className="custom-payment-box">
      <>
        <div className="hero-header">
          <Cs2Icon className="hero" />
          <div className="txts">
            <div className="main">
              {Returntext(contentManagementData, "customDrawerStep2H", lang)}
            </div>
            <div className="sub">
              {Returntext(contentManagementData, "customDrawerStep2B", lang)}
            </div>
          </div>
        </div>

        {/* <div className="info-warn-1">
          <WarnRecIcon className="icon" />
          <span className="txt">
            {Returntext(contentManagementData, "customDrawerStep2Up", lang)}
          </span>
        </div> */}
        <UploadBox
          type={"ADDITIONALFILES"}
          setFiles={setFiles}
          files={files}
          activeOrder={activeOrder}
        />
      </>
    </div>
  );
};

export default CustomPaymentBox;
