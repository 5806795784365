import React, { useEffect, useState } from "react";
import "./timeSlotBox.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import axios from "axios";
import Cookies from "js-cookie";
import { useTimeSlot } from "../../../hooks/ts";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import RenderIcon from "./RenderIcon";

const TimeSlotBox = ({ ts, setTs }) => {
  const [dt, setDt] = useState("");

  const today = new Date(dt);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const { timeSlots } = useTimeSlot();
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const checkTime = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/district/date?accessToken=${access_token}`
        );

        setDt(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    checkTime();
  }, []);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  let month = "";
  let day = "";

  let month2 = "";
  let day2 = "";

  if (today) {
    const dateObject = new Date(today);
    month = dateObject.toLocaleString("default", { month: "short" });
    day = dateObject.getDate();
  }

  if (tomorrow) {
    const dateObject = new Date(tomorrow);
    month2 = dateObject.toLocaleString("default", { month: "short" });
    day2 = dateObject.getDate();
  }

  console.log(month);
  return (
    <div className="time-slot-box">
      {timeSlots?.today?.length > 0 && (
        <div className="time-item">
          <div className="title">
            {month &&
              day &&
              `${day} ${Returntext(
                contentManagementData,
                month?.toLowerCase(),
                lang
              )}`}
          </div>
          <div className="ts-items">
            {timeSlots?.today?.map((item, i) => (
              <BtnStandardIconText
                key={i}
                mode={""}
                txt={item}
                disabled={false}
                size={"sm"}
                icon={true}
                iconPosition={"right"}
                svg={<RenderIcon iconType={item} />} // Use the SVG component here
                mainColor={ts === item ? "green" : "grayBorder"}
                onClick={() => {
                  setTs(item);
                }}
              />
            ))}
          </div>
        </div>
      )}

      {timeSlots?.tomorrow?.length > 0 && (
        <div className="time-item">
          <div className="title">
            {month2 &&
              day2 &&
              `${day2} ${Returntext(
                contentManagementData,
                month2?.toLowerCase(),
                lang
              )}`}
          </div>
          <div className="ts-items">
            {timeSlots?.tomorrow?.map((item, i) => (
              <BtnStandardIconText
                key={i}
                mode={""}
                txt={item}
                disabled={false}
                size={"sm"}
                icon={true}
                iconPosition={"right"}
                svg={<RenderIcon iconType={item} />} // Use the SVG component here
                mainColor={ts === item ? "green" : "grayBorder"}
                onClick={() => {
                  setTs(item);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSlotBox;
