import React from "react";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import BtnCircular from "../BtnCircular/BtnCircular";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import "./drawer.scss";

// Import the SVG as a React component
import { ReactComponent as CloseIcon } from "../../icons/x.svg";
import useMobileVhFix from "../../hooks/height";

const Drawer = ({
  headerTitle,
  body,
  onClick,
  cancel,
  btnMainTxt,
  mainBtnDisable,
  size,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  useMobileVhFix();

  return (
    <div className="drawer">
      <div className="shadow" onClick={cancel} />
      <div className="main-drawer-wrap">
        <div className="header">
          <div className="left"></div>
          <div className="middle">{headerTitle}</div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={cancel}
          />
        </div>

        <div className={`body-c ${size === "s" && "s"}`}>{body}</div>

        <div className={`footer-c ${size === "s" && "s"}`}>
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "cancel", lang)}
            disabled={false}
            size={"md"}
            icon={false}
            mainColor={"white"}
            onClick={cancel}
          />
          <BtnStandardIconText
            mode=""
            txt={btnMainTxt}
            disabled={!mainBtnDisable ? false : true}
            size={"md"}
            icon={false}
            mainColor={"green"}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Drawer;
