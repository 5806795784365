import React from "react";
import "./customFooterS1.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const CustomFooterS1 = ({
  setPInfoDrawer,
  notPaid,
  cancelClick,
  handleUpdateAuto,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="custom-footer-s1">
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "cancel", lang)}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"white"}
        onClick={cancelClick}
      />
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "confirm", lang)}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"black"}
        onClick={() => {
          if (notPaid) {
            handleUpdateAuto();
          } else {
            setPInfoDrawer(true);
          }
        }}
      />
    </div>
  );
};

export default CustomFooterS1;
