import React, { useEffect, useState } from "react";
import "./paymentDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import PaymentNusxa from "../../molecules/PaymentNusxa/PaymentNusxa";
import SelectAddress from "../../molecules/SelectAddress/SelectAddress";
import PaymentSummary from "../../molecules/PaymentSummary/PaymentSummary";
import ListItem from "../../../ds/ListItem/ListItem";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import ChooseAddressBox from "../../molecules/ChooseAddressBox/ChooseAddressBox";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useCustomerAddresses } from "../../../hooks/address";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrow-r.svg";
import useMobileVhFix from "../../../hooks/height";

const PaymentDrawer = ({
  cancelClick,
  activeOrder,
  nextClick,
  setAddressDrawer,
  all,
  setActiveOrder,
  useraddress,
}) => {
  const [selectedOrders, setSelectedOrders] = useState([
    activeOrder !== undefined && activeOrder,
  ]);

  const [totalValue, setTotalValue] = useState(activeOrder?.prices?.total);

  const [cadActive, setCadActive] = useState(false);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { customerAddresses } = useCustomerAddresses();

  const [address, setAddress] = useState(
    customerAddresses !== undefined && customerAddresses.length !== 0
      ? customerAddresses.find((address) => address.isDefault === true)
      : ""
  );
  useMobileVhFix();

  return (
    <div className="pod-drawer">
      <div className="shadow" onClick={cancelClick}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(contentManagementData, "PT", lang)}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancelClick}
          />
        </div>

        <div className="body-p">
          <div className="d-wrap">
            <div className="txts">
              <div className="main">
                {Returntext(contentManagementData, "PDT", lang)}
              </div>
              <div className="sub">
                {Returntext(contentManagementData, "PDB", lang)}
              </div>
            </div>
            {address !== "" || address !== undefined ? (
              <ListItem
                hasBorder={true}
                hasPadding={true}
                hasIcon={true}
                headline={address.title}
                description={address.address}
                style={{ cursor: "pointer" }}
                onClick={() => setCadActive(!cadActive)}
                icon={
                  address.tag === "home" ? (
                    <HomeIcon /> // Use the SVG component here
                  ) : (
                    <WorkIcon />
                  ) // Use the SVG component here
                }
                buttons={
                  <>
                    {address.isDefault && (
                      <OrderTableLabel
                        txt={Returntext(contentManagementData, "default", lang)}
                      />
                    )}
                    <ArrowRightIcon style={{ marginLeft: "16px" }} />{" "}
                    {/* Use the SVG component here */}
                  </>
                }
              />
            ) : (
              <SelectAddress setAddressDrawer={setAddressDrawer} />
            )}

            {cadActive && (
              <ChooseAddressBox
                address={address}
                setAddress={setAddress}
                setCadActive={setCadActive}
                setAddressDrawer={setAddressDrawer}
              />
            )}
          </div>

          <PaymentNusxa
            setSelectedOrders={setSelectedOrders}
            selectedOrders={selectedOrders}
            activeOrder={activeOrder}
            main={Returntext(contentManagementData, "PST", lang)}
            sub={Returntext(contentManagementData, "PSB", lang)}
            totalValue={totalValue}
            setTotalValue={setTotalValue}
            all={all}
          />

          <PaymentSummary
            nextClick={nextClick}
            selectedOrders={selectedOrders}
            totalValue={totalValue}
            setActiveOrder={setActiveOrder}
            activeOrder={activeOrder}
            address={address}
            cancelClick={cancelClick}
            useraddress={useraddress}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDrawer;
