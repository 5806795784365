import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";

function useCategoryArray() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const categories = Array.from({ length: 25 }, (_, index) => {
    const categoryText = Returntext(
      contentManagementData,
      `Category_${index + 1}`,
      lang
    );
    return categoryText ? categoryText : null; // Return null if empty or undefined
  }).filter((categoryText) => categoryText !== null); // Exclude null values

  return categories;
}

export default useCategoryArray;
