import { useEffect, useState } from "react";
import { BogPaymentSession } from "@bankofgeorgia/bog-payments-web-sdk";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import "./bogPayIframe.scss";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useSnackbar } from "notistack";
import LoaderAnimation from "../../../assets/animations/Loaders/1 - Green/data.json";
import Lottie from "lottie-react";
import { usePaymentCardsQuery } from "../../../hooks/cards";

const PaymentIframe = ({ orderId, setShowModal, setOrderId }) => {
  const [bSess, setSess] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  // const [preflight, setPreflight] = useState(true);
  const [ht, setHt] = useState("100px");
  const [btns, setBtns] = useState(true);
  const [canP, setCanP] = useState(false);
  const [loading, setLoading] = useState(true);
  const { refetchPaymentCards } = usePaymentCardsQuery();
  const [dis, setDis] = useState(false);
  const PayBOG = () => {
    if (bSess) {
      bSess.triggerPayment();
      // setPreflight(false);
    }
    // else {
    //   setPreflight(true);
    // }
  };

  useEffect(() => {
    if (orderId) {
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 1500); // Delay of 500 ms

      const session = new BogPaymentSession(orderId, {
        width: "100%",
        height: "185px",
        lang: "ka",
        theme: "light",
        submitButton: {
          display: false,
        },
        style: {
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          inputs: {
            focusedBorderColor: "black",
            focusedLabelColor: "black",
          },
        },
      });
      const iframe = document.getElementById("bog-payment-iframe");
      session.render(iframe);

      setSess(session);

      session.on("form_layout_changed", (data) => {
        if (data.reason === "3ds_page_displayed") {
          setHt("470px");
          session.resize("100%", "470px");
          setBtns(false);
        }
      });

      session.on("submit_available_changed", (data) => {
        if (data.canPay) {
          setCanP(true);
        } else {
          setCanP(false);
        }
      });

      session.on("payment_complete", (data) => {
        if (data && data.error) {
          setShowModal(false);
          setOrderId(false);
          refetchPaymentCards();
          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-card1", lang),
            {
              variant: "error",
            }
          );
          session.disconnect();
        } else {
          console.log("first");
          setShowModal(false);
          setOrderId(false);
          refetchPaymentCards();
          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-card", lang),
            {
              variant: "success",
            }
          );
          session.disconnect();
        }
        setDis(false);
      });
      return () => clearTimeout(timeoutId);
    } else {
      setLoading(true);
    }
  }, [orderId]);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="bog-iframe">
      {loading && (
        <div className="animation-container">
          <Lottie animationData={LoaderAnimation} className="animation" />
        </div>
      )}

      <div
        className={`middle-bog ${loading && "hidden"}`}
        style={{ height: ht }}
      >
        <div id="bog-payment-iframe"></div>
      </div>

      <div className="bottom-bog">
        <BtnStandardIconText
          mode=""
          txt={Returntext(contentManagementData, "btn_cancel", lang)}
          disabled={false}
          size={"standard"}
          icon={false}
          mainColor={"white"}
          onClick={() => {
            setShowModal(false);
            setOrderId(false);
          }}
        />
        {btns && (
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "btn_save", lang)}
            disabled={canP && !dis ? false : true}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => {
              setDis(true);
              PayBOG();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentIframe;
