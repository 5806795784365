import React, { useEffect, useState } from "react";
import "./RestrictedItemsDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import ListItem from "../../../ds/ListItem/ListItem";
import useMobileVhFix from "../../../hooks/height";

const RestrictedItemsDrawer = ({ cancelClick }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const { restrictedData } = useContent();
  useMobileVhFix();

  return (
    <div className="restricted-address-drawer">
      <div className="shadow" onClick={cancelClick}></div>
      <div className="drawer-wrap">
        <div className="header">
          <div className="left"></div>

          <div className="middle">
            {Returntext(contentManagementData, "Prohibited_Items", lang)}
          </div>

          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />} // Use the SVG component here
            disabled={false}
            mode={"active"}
            onClick={cancelClick}
          />
        </div>
        <div className="body">
          <div className="body-head">
            <div className="main">
              {Returntext(
                contentManagementData,
                "Restricted_Items_Header",
                lang
              )}
            </div>
          </div>

          {restrictedData.length > 0 &&
            restrictedData.map((item) => (
              <ListItem
                key={item.id}
                hasBorder={true}
                hasPadding={true}
                hasIcon={true}
                headline={lang === "ka" ? item.nameKa : item.nameEn}
              />
            ))}

          <div className="body-footer">
            <div className="sub">
              {Returntext(
                contentManagementData,
                "Restricted_Items_Footer",
                lang
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictedItemsDrawer;
