import React, { useEffect, useState } from "react";
import "./addressOn.scss";

import { useContent, useLanguages } from "../../hooks/content";
import { setDefaults } from "react-geocode";
import axios from "axios";
import { useAddressPost } from "../../hooks/address";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import OnboardFormHeader from "../../components/molecules/OnboardFormHeader/OnboardFormHeader";
import BtnLightCircularIconLg from "../../components/atoms/BtnLightCircularIconLg/BtnLightCircularIconLg";
import OnboardWelcomeTextLg from "../../components/atoms/OnboardWelcomeTextLg/OnboardWelcomeTextLg";
import { Returntext } from "../Landings/common/returnText";
import OnboardWelcomeTextMd from "../../components/atoms/OnboardWelcomeTextMd/OnboardWelcomeTextMd";
import BtnStandardIconText from "../../ds/BtnStandardIconText/BtnStandardIconText";
import InputGz from "../../ds/InputGz/InputGz";
import { Autocomplete } from "@react-google-maps/api";
import MapWithAutocomplete from "../../components/molecules/GMap/MapWithAutocomplete";
import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../icons/work.svg";
import { ReactComponent as OtherIcon } from "../../icons/other.svg";

const AddressOn = ({ setLoader }) => {
  const [addressTitle, setAddressTitle] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [tag, setTag] = useState("home");
  const [city, setCity] = useState("");
  const [additionalInstuctions, setAdditionalInstuctions] = useState("");

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  setDefaults({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "en",
    region: "es",
  });

  const [autocomplete, setAutocomplete] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 41.7056322,
    lng: 44.7870996,
  });

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place not found");
          return;
        }
        const { formatted_address } = place;
        setUserAddress(formatted_address);
        setCurrentLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });
    }
  }, [autocomplete]);

  useEffect(() => {
    // Fetch city using reverse geocoding
    const fetchCity = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.lat},${currentLocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&sensor=true`
        );

        if (response.data.results.length > 0) {
          const addressComponents = response.data.results[0].address_components;
          for (let component of addressComponents) {
            if (component.types.includes("locality")) {
              setCity(component.long_name);
              break;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching city:", error);
      }
    };

    fetchCity();
  }, [currentLocation]);

  const userData = JSON.parse(localStorage.getItem("user"));
  const { mutateAddress } = useAddressPost();
  const [addressTitleError, setAddressTitleError] = useState(false);
  const [userAddressError, setUserAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const handleConfirmAddress = () => {
    try {
      if (!addressTitle.trim() || !userAddress.trim() || !city.trim()) {
        setAddressTitleError(!addressTitle.trim());
        setUserAddressError(!userAddress.trim());
        setCityError(!city.trim());
        enqueueSnackbar("Data is Missing, Please Input All the Fields", {
          variant: "error",
        });
        return;
      }

      const addressData = {
        address: userAddress,
        tag: tag,
        title: addressTitle,
        city: city,
        additionalInstructions: additionalInstuctions,
        lang: currentLocation ? currentLocation.lng.toString() : "",
        lat: currentLocation ? currentLocation.lat.toString() : "",
        customer_id: userData.id,
        isDefault: true,
      };

      mutateAddress(addressData);
      navigate("/orders/AWAITING");
    } catch (error) {
      console.error("Error updating address:", error);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adress2", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="address-on">
      <div className="onboarding-wrap">
        <div className="body-wrap">
          <div className="left">
            <div className="content-wrap">
              <div className="top">
                <BtnLightCircularIconLg />
                <OnboardWelcomeTextLg
                  txt={Returntext(
                    contentManagementData,
                    "onboardWelcome",
                    lang
                  )}
                />
              </div>
              <OnboardWelcomeTextMd
                txt={Returntext(contentManagementData, "onboardSW", lang)}
              />
              <img src="/images/heros/onboard.png" alt="" />
            </div>
          </div>

          <div className="right">
            <OnboardFormHeader
              stage={1}
              maintxt={Returntext(contentManagementData, "onboardMapH", lang)}
              subTxt={Returntext(contentManagementData, "onboardMapHS", lang)}
            />

            <div className="address-on-form-body">
              <div className="tags">
                <BtnStandardIconText
                  mode={tag === "home" ? "active" : ""}
                  txt={Returntext(contentManagementData, "home", lang)}
                  disabled={false}
                  size={"sm"}
                  icon={true}
                  svg={<HomeIcon />}
                  iconPosition={"left"}
                  mainColor={"grayBorder"}
                  onClick={() => setTag("home")}
                />
                <BtnStandardIconText
                  mode={tag === "work" ? "active" : ""}
                  txt={Returntext(contentManagementData, "work", lang)}
                  disabled={false}
                  size={"sm"}
                  icon={true}
                  svg={<WorkIcon />}
                  iconPosition={"left"}
                  mainColor={"grayBorder"}
                  onClick={() => setTag("work")}
                />
                <BtnStandardIconText
                  mode={tag === "other" ? "active" : ""}
                  txt={Returntext(contentManagementData, "other", lang)}
                  disabled={false}
                  size={"sm"}
                  icon={true}
                  svg={<OtherIcon />}
                  iconPosition={"left"}
                  mainColor={"grayBorder"}
                  onClick={() => setTag("other")}
                />
              </div>

              <InputGz
                type={"text"}
                value={addressTitle}
                setter={setAddressTitle}
                label={Returntext(contentManagementData, "Title", lang)}
                nid={"title"}
                disabled={addressTitleError}
                errorM={false}
              />
              <Autocomplete
                onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                options={{
                  placeholder: "",
                  componentRestrictions: { country: "ge" },
                }}
              >
                <InputGz
                  type={"text"}
                  value={userAddress}
                  setter={setUserAddress}
                  nid={"adress"}
                  disabled={false}
                  errorM={userAddressError}
                  hasLabel={true}
                />
              </Autocomplete>

              <InputGz
                type={"text"}
                value={city}
                setter={""}
                label={Returntext(contentManagementData, "city", lang)}
                nid={"city"}
                disabled={true}
                errorM={cityError}
              />

              <MapWithAutocomplete
                setStreetName={setUserAddress}
                setCurrentLocation={setCurrentLocation}
                currentLocation={currentLocation}
                small={true}
              />

              <InputGz
                type={"text"}
                value={additionalInstuctions}
                setter={setAdditionalInstuctions}
                label={Returntext(
                  contentManagementData,
                  "AdditionalInformation",
                  lang
                )}
                nid={"Additional instructions"}
                disabled={false}
                errorM={false}
              />

              <div className="btns">
                <BtnStandardIconText
                  mode=""
                  txt={Returntext(contentManagementData, "confirm", lang)}
                  disabled={
                    userAddress !== "" && addressTitle !== "" && city !== ""
                      ? false
                      : true
                  }
                  size={"standard"}
                  icon={false}
                  mainColor={"green"}
                  onClick={() => handleConfirmAddress()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressOn;
