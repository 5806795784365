import { useEffect } from "react";

const useMobileVhFix = () => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Set the initial value on load
    setVh();

    // Update the value on resize and orientation change
    window.addEventListener("resize", setVh);
    window.addEventListener("orientationchange", setVh);

    // Clean up the event listeners
    return () => {
      window.removeEventListener("resize", setVh);
      window.removeEventListener("orientationchange", setVh);
    };
  }, []);
};

export default useMobileVhFix;
