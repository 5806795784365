import React from "react";
import "./shippingAddressBody.scss";
import ShipmentInformation from "../../molecules/ShipmentInformation/ShipmentInformation";
import ShipmentMaterials from "../../molecules/ShipmentMaterials/ShipmentMaterials";

const ShippingAddressBody = ({
  restrictedItemsDrawer,
  setRestrictedItemsDrawer,
}) => {
  return (
    <div className="shipping-address-body-c">
      <ShipmentInformation />
      <ShipmentMaterials
        restrictedItemsDrawer={restrictedItemsDrawer}
        setRestrictedItemsDrawer={setRestrictedItemsDrawer}
      />
    </div>
  );
};

export default ShippingAddressBody;
