import React, { useEffect, useRef, useState } from "react";
import "./paymentNusxa.scss";
import axios from "axios";
import Cookies from "js-cookie";
import OrderPriceDetails from "../OrderPriceDetails/OrderPriceDetails";
import Lottie from "lottie-react";
import LoaderAnimation from "../../../assets/animations/Loaders/1 - Green/data.json";
import { ReactComponent as ScrollA } from "../../../icons/scroll-a.svg";

const PaymentNusxa = ({
  main,
  sub,
  activeOrder,
  selectedOrders,
  setSelectedOrders,
  totalValue,
  setTotalValue,
  all,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [baseOrders, setBaseOrders] = useState([]);
  const access_token = Cookies.get("access_token");

  useEffect(() => {
    const fetchUnpaidOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/orders/getUnpaidOrders/${user.id}?accessToken=${access_token}`
        );

        setBaseOrders(response?.data?.filteredOrders);
      } catch (error) {
        console.error("Error fetching unpaid orders:", error);
      }
    };

    fetchUnpaidOrders();
  }, []);

  const [actives, setActives] = useState(
    !all
      ? [activeOrder !== undefined && activeOrder.id]
      : selectedOrders?.map((item) => item.id)
  );

  const handleToggleOrder = (order) => {
    const orderIndex = selectedOrders.findIndex((item) => item === order);
    const index = actives.indexOf(order.id);

    if (actives?.length === 1 && order.id === actives[0]) {
      return;
    }

    if (actives.includes(order.id)) {
      const updatedSelects = [...selectedOrders];
      updatedSelects.splice(orderIndex, 1);
      setSelectedOrders(updatedSelects);

      const updatedActives = [...actives];
      updatedActives.splice(index, 1);
      setActives(updatedActives);

      setTotalValue(totalValue - order?.prices?.total);
    } else if (!actives.includes(order.id)) {
      setSelectedOrders([...selectedOrders, order]);
      setActives([...actives, order.id]);
    }
  };

  const handleToggleAllOrder = (order) => {
    const index = actives.indexOf(order.id);

    if (order.id === actives[0]) {
      return;
    }
    if (actives.includes(order.id)) {
      const updatedSelects = [...selectedOrders];
      updatedSelects.splice(index, 1);
      setSelectedOrders(updatedSelects);

      const updatedActives = [...actives];
      updatedActives.splice(index, 1);
      setActives(updatedActives);

      setTotalValue(totalValue - order?.prices?.total);
    } else if (!actives.includes(order.id)) {
      setSelectedOrders([...selectedOrders, order]);
      setActives([...actives, order.id]);
    }
  };

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="payment-nusxa">
      <div className="txts">
        <div className="main">{main}</div>
        <div className="sub">{sub}</div>
      </div>

      {loader ? (
        <div className="drawer-loader">
          <Lottie animationData={LoaderAnimation} className="animation" />
        </div>
      ) : (
        <div
          className="items-c"
          style={{ overflowY: "auto", maxHeight: "400px" }}
        >
          <div className="items">
            {baseOrders?.map((order, i) => (
              <OrderPriceDetails
                order={order}
                key={i}
                actives={actives}
                all={all}
                handleToggleAllOrder={handleToggleAllOrder}
                handleToggleOrder={handleToggleOrder}
              />
            ))}
          </div>
          {/* <ScrollA className="icon" onClick={scrollToBottom} /> */}
        </div>
      )}
    </div>
  );
};

export default PaymentNusxa;
