import React, { useState } from "react";
import "./accountPopUp.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import Cookies from "js-cookie";
import { useResetPasswordMutation } from "../../../hooks/auth";
import ResetPasswordPopUpBody from "../AccountPopUpBodies/ResetPasswordPopUpBody";
import { useSnackbar } from "notistack";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const AccountPopUp = ({ title, msg, setPopUpActive }) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const resetPasswordMutation = useResetPasswordMutation();

  const { enqueueSnackbar } = useSnackbar();

  const HandlesetPassword = () => {
    const accessToken = Cookies.get("access_token");

    if (rePassword !== newPassword) {
      setPasswordError(true);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-signin5", lang),
        {
          variant: "error",
        }
      );
      return;
    } else if (rePassword !== newPassword) {
      setPasswordError(false);
    }

    if (
      password.length < 8 ||
      rePassword.length < 8 ||
      newPassword.length < 8
    ) {
      enqueueSnackbar(
        "Password must contain a letter and a number, and be minimum of 9 character",
        {
          variant: "error",
        }
      );
      setPasswordError(true);
      return;
    }

    try {
      resetPasswordMutation.mutate({
        access_token: accessToken,
        currentPassword: password,
        newPassword: newPassword,
      });
      setPopUpActive(false);
      setPassword("");
      setNewPassword("");
      setRePassword("");
      setPasswordError(false);

      enqueueSnackbar("Password reset successful! 🎉", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error setting password:", error);
    }
  };

  return (
    <div className="account-pop-up">
      <div className="pop-wrap">
        <div className="top">
          <div className="title">{title}</div>
          <BtnCircular
            size={"sm"}
            mainColor={"white"}
            svg={<CloseIcon />}
            disabled={false}
            mode={"active"}
            onClick={() => setPopUpActive(false)}
          />
        </div>

        <div className="middle">
          <div className="msg">{msg}</div>
          <ResetPasswordPopUpBody
            password={password}
            setPassword={setPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            rePassword={rePassword}
            setRePassword={setRePassword}
            passwordError={passwordError}
          />
        </div>

        <div className="bottom">
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "cancel", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"white"}
            onClick={() => setPopUpActive(false)}
          />

          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "confirm", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => HandlesetPassword()}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountPopUp;
