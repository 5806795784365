import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Cookies from "js-cookie";
import refreshAccessToken from "./refresh";
import { useSnackbar } from "notistack";
import { useContent, useLanguages } from "./content";
import { Returntext } from "../pages/Landings/common/returnText";

export const useCustomerAddresses = () => {
  const { isLoading, error, data, refetch } = useQuery(
    ["addresses"],
    async () => {
      try {
        const access_token = Cookies.get("access_token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/customer-addresses/customerAdress?accessToken=${access_token}`
        );
        return response.data;
      } catch (err) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          refreshAccessToken();
          const access_token = Cookies.get("access_token");

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/customer-addresses/customerAdress?accessToken=${access_token}`
          );
          return response.data;
        } else {
          console.log("Error:", err);
          localStorage.setItem("user", null);
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          return [];
        }
      }
    }
  );

  let customerAddresses = data ? data : [];

  return {
    customerAddresses,
    customerAddressesLoading: isLoading,
    customerAddressesError: error,
    customerAddressesRefetch: refetch,
  };
};

const useAddressPost = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const postAddress = async (addressData) => {
    try {
      // Make API call to post address data
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer-addresses?accessToken=${access_token}`,
        addressData
      );

      // On successful post, trigger user data refetch
      await queryClient.invalidateQueries("addresses");

      return response.data; // Return the response data if needed
    } catch (error) {
      throw new Error(error.response?.data?.message || "Address post failed");
    }
  };

  const { mutate: mutateAddress } = useMutation(postAddress);

  return { mutateAddress };
};

const useAddressDelete = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");
  const { enqueueSnackbar } = useSnackbar();
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const deleteAddress = async (addressId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/customer-addresses/${addressId}?accessToken=${access_token}`
      );

      await queryClient.invalidateQueries("addresses");
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adressdelete", lang),
        {
          variant: "success",
        }
      );
      return true;
    } catch (error) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-adressdelete2", lang),
        {
          variant: "error",
        }
      );
      throw new Error(error.response?.data?.message || "Address delete failed");
    }
  };

  const { mutate: mutateDeleteAddress } = useMutation(deleteAddress);

  return { mutateDeleteAddress };
};

export const useUpdateAddressMutation = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const updateAdressMutation = useMutation(
    async ({ addressId, data }) => {
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/customer-addresses/${addressId}?accessToken=${access_token}`,
          data
        );
      } catch (error) {
        throw new Error(
          error.response?.data?.message || "Declaration update failed"
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("addresses");
      },
    }
  );
  const handleUpdateAddress = (addressId, data) => {
    updateAdressMutation.mutateAsync({ addressId, data });
  };

  return {
    handleUpdateAddress,
  };
};

const useAddressPost2 = () => {
  const queryClient = useQueryClient();
  const access_token = Cookies.get("access_token");

  const postAddress = async (addressData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer-addresses?accessToken=${access_token}`,
        addressData
      );

      console.log(response); // Log the response

      await queryClient.invalidateQueries("addresses"); // On successful post, trigger user data refetch

      return response.data; // Return the response data
    } catch (error) {
      throw new Error(error.response?.data?.message || "Address post failed"); // Throw error if post fails
    }
  };

  const mutateAddress = useMutation(postAddress);

  return {
    mutateAddress: mutateAddress.mutate,
    refetchUserDataOnSuccess: queryClient.invalidateQueries.bind(
      queryClient,
      "addresses"
    ),
  };
};

export default useAddressPost2;
export { useAddressPost, useAddressDelete };
