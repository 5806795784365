import React from "react";
import "./customFooterS2.scss";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";

const CustomFooterS2 = ({ handleUpdateCustomStatus, cancelClick, step }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="custom-footer-s1">
      <BtnStandardIconText
        mode=""
        txt={Returntext(contentManagementData, "btn_cancel", lang)}
        disabled={false}
        size={"standard"}
        icon={false}
        mainColor={"white"}
        onClick={() => cancelClick()}
      />
    </div>
  );
};

export default CustomFooterS2;
