import { useNavigate } from "react-router-dom";
import BtnStandardLanding from "../../../ds/BtnStandardLanding/BtnStandardLanding";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import LanguageBtn from "../../atoms/LanguageBtn/LanguageBtn";
import NavbarLanding from "../../molecules/NavbarLanding/NavbarLanding";
import "./header.scss";
import { useEffect, useState } from "react";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import BurgerAuthed from "./Burger/BurgerAuthed";
import HeaderLogoAuthed from "../../atoms/Logo/HeaderLogoAuthed";
import Cookies from "js-cookie";
import useOrderPrices from "../../../hooks/prices";
import { useUnpaidOrders } from "../../../hooks/orders";
import { ReactComponent as BurgerIcon } from "../../../icons/landing/common/burger.svg";
import { ReactComponent as HomeIcon } from "../../../icons/landing/common/burger/home.svg";
import { ReactComponent as WalletIcon } from "../../../icons/landing/common/burger/wallet.svg";
import { ReactComponent as DeliveryIcon } from "../../../icons/landing/common/burger/delivery.svg";
import { ReactComponent as ServicesIcon } from "../../../icons/landing/common/burger/services.svg";
import { ReactComponent as PriceIcon } from "../../../icons/landing/common/burger/price.svg";
import { ReactComponent as AboutUsIcon } from "../../../icons/landing/common/burger/aboutUs.svg";
import { ReactComponent as TermsAndConditionsIcon } from "../../../icons/landing/common/burger/termsAndConditions.svg";

const HeaderAuthed = ({ setPDrawerActive }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const [active, setActive] = useState(false);
  const [orderData, setOrderData] = useState(0);
  const [navHidden, setNavHidden] = useState(false);
  const [hid, setHid] = useState(false);
  const navigate = useNavigate();
  const [burgerShow, setBurgerShow] = useState(false);
  const access_token = Cookies.get("access_token");
  const [selectedOrders, setSelectedOrders] = useState([]);

  const landingBurgerData = {
    item1: [
      {
        name: Returntext(contentManagementData, "orders", lang),
        image: <HomeIcon />,
        link: "/orders",
      },
      {
        name: Returntext(contentManagementData, "wallet", lang),
        image: <WalletIcon />,
        link: "/wallet",
      },
      {
        name: Returntext(contentManagementData, "delivery", lang),
        image: <DeliveryIcon />,
        link: "/delivery",
      },
      {
        name: Returntext(contentManagementData, "services", lang),
        image: <ServicesIcon />,
        link: "/services",
      },
    ],
    item2: [
      {
        name: Returntext(contentManagementData, "navbar_home", lang),
        image: <HomeIcon />,
        link: "/landing/home",
      },
      {
        name: Returntext(contentManagementData, "navbar_prices", lang),
        image: <PriceIcon />,
        link: "/landing/prices",
      },
      {
        name: Returntext(contentManagementData, "navbar_delivery", lang),
        image: <DeliveryIcon />,
        link: "/landing/delivery",
      },
      {
        name: Returntext(contentManagementData, "navbar_aboutus", lang),
        image: <AboutUsIcon />,
        link: "/landing/about",
      },
      {
        name: Returntext(contentManagementData, "TAC", lang),
        image: <TermsAndConditionsIcon />,
        link: "/landing/terms",
      },
      // {
      //   name: Returntext(contentManagementData, "PP", lang),
      //   image: <PrivacyIcon />,
      // },
    ],
    item5: [
      {
        component: <LanguageBtn burgerComp />,
      },
    ],
  };
  const showBurger = () => {
    setBurgerShow(!burgerShow);
  };

  const handleSettleClick = () => {
    setPDrawerActive(true);
  };

  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const { unpadidOrders } = useUnpaidOrders(userData.id);

  useEffect(() => {
    unpadidOrders?.filteredOrders?.length > 0 && setActive(true);
    setSelectedOrders(unpadidOrders?.filteredOrders?.map((item) => item.id));
    setOrderData(unpadidOrders?.total);
  }, [unpadidOrders]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (currentUrl.includes("landing")) {
      setNavHidden(true);
    } else {
      setNavHidden(false);
    }
  }, [window.location.pathname]);

  const { data, refetchOrderPrices } = useOrderPrices({
    orders: selectedOrders,
    address_id: userData?.customerAddresses[0]?.id,
  });

  return (
    <>
      <header
        className={navHidden ? "header_container2 hidden" : "header_container2"}
      >
        <HeaderLogoAuthed />
        <NavbarLanding />
        {/* web view */}
        <div className="buttonDivs">
          <LanguageBtn />
          <BtnStandardLanding
            mode="active"
            txt={Returntext(contentManagementData, "navbar_btn", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => navigate("/sign-in")}
          />
        </div>
        {/* mobile view */}

        <div className="burger_bar">
          {
            active && data !== undefined && data.length > 0 && (
              <BtnStandardIconText
                mode=""
                mainColor={"red"}
                txt={`${Returntext(
                  contentManagementData,
                  "navbar_btn_settle",
                  lang
                )} ₾${
                  data !== undefined && data.length > 0
                    ? data[1][0]?.totalPrice.toFixed(2)
                    : "0.00"
                }`}
                disabled={false}
                size={"standard"}
                icon={false}
                iconLeft={false}
                onClick={handleSettleClick}
              />
            )
            // : (
            //   <BtnStandardIconText
            //     mode=""
            //     txt={Returntext(contentManagementData, "navbar_btn_settle", lang)}
            //     disabled={true}
            //     size={"standard"}
            //     icon={true}
            //     svg={<WalletGrayIcon />}
            //     iconPosition={"left"}
            //     mainColor={"grayBorder"}
            //     onClick={handleSettleClick}
            //   />
            // )
          }

          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
              width: "60px",
            }}
            onClick={showBurger}
          >
            <img
              alt=""
              src="/images/icons/landing/common/burger.svg"
              width={"20px"}
              height={"20px"}
            />
          </div>
        </div>
      </header>
      {burgerShow ? (
        <BurgerAuthed
          data={landingBurgerData}
          showNavbarButtons
          setBurgerShow={setBurgerShow}
          navHidden={navHidden}
          setNavHidden={setNavHidden}
          burgerShow={burgerShow}
        />
      ) : null}
    </>
  );
};

export default HeaderAuthed;
