import React from "react";
import "./shipmentMaterials.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { ReactComponent as ProhIcon } from "../../../icons/proh.svg";
import useMobileVhFix from "../../../hooks/height";

const ShipmentMaterials = ({ setRestrictedItemsDrawer }) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  useMobileVhFix();

  return (
    <div className="shipment-materials">
      <AccountDetailsBoxTitle
        main={Returntext(contentManagementData, "ShipmentMaterials", lang)}
        sub={Returntext(
          contentManagementData,
          "ShipmentMaterialsSubheadline",
          lang
        )}
      />

      <div className="box-wrap">
        <ProhIcon className="logo" />

        <div className="proh-info-box">
          <div className="top">
            {/* <div className="main">
              {Returntext(
                contentManagementData,
                "ShipmentMaterialsHeadlinen2",
                lang
              )}
            </div>
            <div className="sub">
              {Returntext(
                contentManagementData,
                "ShipmentMaterialsSubheadlinen2	",
                lang
              )}
            </div> */}

            <div className="main">
              {Returntext(contentManagementData, "Prohibited_Items", lang)}
            </div>
            <div className="sub">
              {Returntext(contentManagementData, "Prohibited_Items", lang)}
            </div>
          </div>

          <div
            className="learn-more"
            onClick={() => setRestrictedItemsDrawer(true)}
          >
            {Returntext(contentManagementData, "Prohibited_Items", lang)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentMaterials;
