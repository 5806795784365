import { useNavigate } from "react-router-dom";
import BtnStandardLanding from "../../../ds/BtnStandardLanding/BtnStandardLanding";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import LanguageBtn from "../../atoms/LanguageBtn/LanguageBtn";
import HeaderLogo from "../../atoms/Logo/HeaderLogo";
import NavbarLanding from "../../molecules/NavbarLanding/NavbarLanding";
import "./header.scss";
import Burger from "./Burger/Burger";
import { useEffect, useState } from "react";
import { ReactComponent as BurgerIcon } from "../../../icons/landing/common/burger.svg";
import { ReactComponent as HomeIcon } from "../../../icons/landing/common/burger/home.svg";
import { ReactComponent as PriceIcon } from "../../../icons/landing/common/burger/price.svg";
import { ReactComponent as DeliveryIcon } from "../../../icons/landing/common/burger/delivery.svg";
import { ReactComponent as AboutUsIcon } from "../../../icons/landing/common/burger/aboutUs.svg";
import { ReactComponent as TermsAndConditionsIcon } from "../../../icons/landing/common/burger/termsAndConditions.svg";
import { ReactComponent as PrivacyIcon } from "../../../icons/landing/common/burger/privacy.svg";

const Header = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const navigate = useNavigate();
  const [burgerShow, setBurgerShow] = useState(false);

  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const landingBurgerData = {
    item1: [
      {
        name: Returntext(contentManagementData, "navbar_home", lang),
        image: <HomeIcon />,
        link: "/landing/home",
      },
      {
        name: Returntext(contentManagementData, "navbar_prices", lang),
        image: <PriceIcon />,
        link: "/landing/prices",
      },
      {
        name: Returntext(contentManagementData, "navbar_delivery", lang),
        image: <DeliveryIcon />,
        link: "/landing/delivery",
      },
      {
        name: Returntext(contentManagementData, "navbar_aboutus", lang),
        image: <AboutUsIcon />,
        link: "/landing/about",
      },
      {
        name: Returntext(contentManagementData, "TAC", lang),
        image: <TermsAndConditionsIcon />,
        link: "/landing/terms",
      },
    ],
    // item2: [
    // {
    //   name: Returntext(contentManagementData, "TAC", lang),
    //   image: <TermsAndConditionsIcon />,
    //   link: "/landing/terms",
    // },
    // {
    //   name: Returntext(contentManagementData, "PP", lang),
    //   image: <PrivacyIcon />,
    // },
    // ],

    item3: [
      {
        component: <LanguageBtn burgerComp />,
      },
    ],
  };
  const showBurger = () => {
    setBurgerShow(!burgerShow);
  };

  useEffect(() => {
    burgerShow
      ? document.body.classList.add("fixed-bd")
      : document.body.classList.remove("fixed-bd");
  }, [burgerShow]);

  return (
    <>
      <header className={`header_container`}>
        <HeaderLogo />
        <NavbarLanding />
        {/* web view */}
        <div className="buttonDivs">
          <LanguageBtn />
          {!userData ? (
            <BtnStandardLanding
              mode="active"
              txt={Returntext(contentManagementData, "navbar_btn", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => navigate("/sign-in")}
            />
          ) : (
            <BtnStandardLanding
              mode="active"
              txt={Returntext(contentManagementData, "dashboard_text", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => navigate("/orders/AWAITING")}
            />
          )}
        </div>
        {/* mobile view */}

        <div className="burger_bar">
          {!userData ? (
            <BtnStandardLanding
              mode="active"
              txt={Returntext(contentManagementData, "navbar_btn", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => navigate("/sign-in")}
            />
          ) : (
            <BtnStandardLanding
              mode="active"
              txt={Returntext(contentManagementData, "dashboard_text", lang)}
              disabled={false}
              size={"standard"}
              icon={false}
              mainColor={"green"}
              onClick={() => navigate("/sign-in")}
            />
          )}
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={showBurger}
          >
            <img
              alt=""
              src="/images/icons/landing/common/burger.svg"
              width={"20px"}
              height={"20px"}
            />
          </div>
        </div>
      </header>
      {burgerShow ? (
        <Burger
          data={landingBurgerData}
          showNavbarButtons
          setBurgerShow={setBurgerShow}
          burgerShow={burgerShow}
        />
      ) : null}
    </>
  );
};

export default Header;
