import ShippingAddressHeader from "../../components/molecules/ShippingAddressHeader/ShippingAddressHeader";
import ShippingAddressBody from "../../components/organisms/ShippingAddressBody/ShippingAddressBody";
import useMobileVhFix from "../../hooks/height";
import "./shippingAddress.scss";

import React, { useState } from "react";

const ShippingAddress = ({
  restrictedItemsDrawer,
  setRestrictedItemsDrawer,
}) => {
  const [country, setCountry] = useState("USA");
  useMobileVhFix();

  return (
    <div className="shipping-address">
      <ShippingAddressHeader country={country} setCountry={setCountry} />
      <ShippingAddressBody
        restrictedItemsDrawer={restrictedItemsDrawer}
        setRestrictedItemsDrawer={setRestrictedItemsDrawer}
      />
    </div>
  );
};

export default ShippingAddress;
