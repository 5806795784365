import React, { useEffect, useState } from "react";
import "./paymentDrawer.scss";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import PaymentNusxa from "../../molecules/PaymentNusxa/PaymentNusxa";
import SelectAddress from "../../molecules/SelectAddress/SelectAddress";
import PaymentSummary from "../../molecules/PaymentSummary/PaymentSummary";
import ListItem from "../../../ds/ListItem/ListItem";
import OrderTableLabel from "../../../ds/OrderTableLabel/OrderTableLabel";
import ChooseAddressBox from "../../molecules/ChooseAddressBox/ChooseAddressBox";
import AddressDrawer from "../AddressDrawer/AddressDrawer";
import DeliveryDrawer from "../../molecules/DeliveryDrawer/DeliveryDrawer";
import { useCustomerAddresses } from "../../../hooks/address";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";
import { ReactComponent as HomeIcon } from "../../../icons/home.svg";
import { ReactComponent as WorkIcon } from "../../../icons/work.svg";
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrow-r.svg";
import { useUnpaidOrders } from "../../../hooks/orders";
import { useUserData } from "../../../hooks/users";
import useMobileVhFix from "../../../hooks/height";

const SettlePaymentDrawer = ({ cancelClick, setActiveOrder, activeOrder }) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [paymentSuccess, setPaymentSucces] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const { data: userData } = useUserData();

  const [cadActive, setCadActive] = useState(false);

  const { unpadidOrders } = useUnpaidOrders(userData.id);

  useEffect(() => {
    setSelectedOrders(unpadidOrders.filteredOrders);
    setTotalValue(unpadidOrders.total);

    if (unpadidOrders.filteredOrders.length > 0) {
      setActiveOrder(unpadidOrders.filteredOrders[0]);
    } else {
      setActiveOrder([]);
    }
  }, [unpadidOrders]);

  const { customerAddresses } = useCustomerAddresses();

  const [address, setAddress] = useState(
    customerAddresses !== undefined && customerAddresses.length !== 0
      ? customerAddresses.find((address) => address.isDefault === true)
      : ""
  );

  const [addressDrawer, setAddressDrawer] = useState(false);

  const nextClick = () => {
    setPaymentSucces(true);
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  useMobileVhFix();

  return (
    <div className={paymentSuccess ? "" : "pod-drawer"}>
      {!paymentSuccess && (
        <>
          <div className="shadow" onClick={cancelClick}></div>
          <div className="drawer-wrap">
            <div className="header">
              <div className="left"></div>

              <div className="middle">
                {Returntext(contentManagementData, "PT", lang)}
              </div>

              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />} // Use the SVG component here
                disabled={false}
                mode={"active"}
                onClick={cancelClick}
              />
            </div>

            {selectedOrders?.length > 0 && (
              <div className="body-c">
                <div className="d-wrap">
                  <div className="txts">
                    <div className="main">
                      {Returntext(contentManagementData, "PDT", lang)}
                    </div>
                    <div className="sub">
                      {Returntext(contentManagementData, "PDB", lang)}
                    </div>
                  </div>
                  {address !== "" ? (
                    <ListItem
                      hasBorder={true}
                      hasPadding={true}
                      hasIcon={true}
                      headline={address.title}
                      description={address.address}
                      style={{ cursor: "pointer" }}
                      onClick={() => setCadActive(!cadActive)}
                      icon={
                        address.tag === "home" ? <HomeIcon /> : <WorkIcon />
                      }
                      buttons={
                        <>
                          {address.isDefault && (
                            <OrderTableLabel
                              txt={Returntext(
                                contentManagementData,
                                "default",
                                lang
                              )}
                            />
                          )}
                          <ArrowRightIcon style={{ marginLeft: "16px" }} />
                        </>
                      }
                    />
                  ) : (
                    <SelectAddress setAddressDrawer={setAddressDrawer} />
                  )}

                  {cadActive && (
                    <ChooseAddressBox
                      address={address}
                      setAddress={setAddress}
                      setCadActive={setCadActive}
                      setAddressDrawer={setAddressDrawer}
                    />
                  )}
                </div>

                <PaymentNusxa
                  setSelectedOrders={setSelectedOrders}
                  selectedOrders={selectedOrders}
                  activeOrder={activeOrder}
                  main={Returntext(contentManagementData, "PST", lang)}
                  sub={Returntext(contentManagementData, "PSB", lang)}
                  totalValue={totalValue}
                  setTotalValue={setTotalValue}
                  all={true}
                />

                <PaymentSummary
                  nextClick={nextClick}
                  selectedOrders={selectedOrders}
                  totalValue={totalValue}
                  setActiveOrder={setActiveOrder}
                  activeOrder={activeOrder}
                  address={address}
                  cancelClick={cancelClick}
                />
              </div>
            )}
          </div>
        </>
      )}
      {addressDrawer && (
        <AddressDrawer
          cancelClick={() => setAddressDrawer(false)}
          setUserAddress={setAddress}
          style={{ zIndex: "999" }}
        />
      )}
      {paymentSuccess && (
        <DeliveryDrawer cancelClick={cancelClick} activeOrder={activeOrder} />
      )}
    </div>
  );
};

export default SettlePaymentDrawer;
