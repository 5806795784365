import { useState, useCallback } from "react";
import "./slider.scss";
import { useLanguages } from "../../../hooks/content";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { icon_left, icon_right } from "../../../assets/landing";

const Slider = ({ showMovers = true, data = [] }) => {
  const { lang } = useLanguages();
  const [swiperRef, setSwiperRef] = useState();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <div>
      {showMovers ? (
        <>
          <Swiper
            loop={true}
            modules={[Navigation, Pagination]}
            slidesPerView={"auto"}
            className="swiper_texts"
            spaceBetween={10}
            navigation
            pagination={{
              el: ".swiper-custom-pagination",
            }}
            onSwiper={setSwiperRef}
          >
            {data.map((slide, index) => (
              <SwiperSlide key={`slide${index}`} className="slides">
                <div>
                  {Array.isArray(slide.greenWords) &&
                  slide.greenWords.length > 0
                    ? lang === "ka"
                      ? slide.selectTextKa.split(" ").map((word, i) => (
                          <span
                            key={i}
                            className={
                              slide.greenWords.includes(i) ? "green-word" : ""
                            }
                          >
                            {word}{" "}
                          </span>
                        ))
                      : lang === "en"
                      ? slide.selectTextEn.split(" ").map((word, i) => (
                          <span
                            key={i}
                            className={
                              slide.greenWords.includes(i + 1)
                                ? "green-word"
                                : ""
                            }
                          >
                            {word}{" "}
                          </span>
                        ))
                      : ""
                    : lang === "ka"
                    ? slide.selectTextKa
                    : slide.selectTextEn}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="vertical-slider">
            <div className="swiper-custom-pagination" />
            <div>
              <button className="arrow-button" onClick={handlePrevious}>
                <img className="lang-icon" alt="" src={icon_left} />
              </button>
              <button className="arrow-button" onClick={handleNext}>
                <img className="lang-icon" alt="" src={icon_right} />
              </button>
            </div>
          </div>
        </>
      ) : (
        // Your Swiper component
        <Swiper
          slidesPerView={4}
          spaceBetween={20}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {data.map((slide, index) => (
            <SwiperSlide key={`slide${index}`} className="swiper_images">
              <div className="img_div">
                <img key={index} src={slide.src} width={"100%"} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default Slider;
